<template>
  <div class="MonitorOverviewStatisticTable">
    <div class="card">
      <div class="card-body">
        <table class="table">
          <thead>
          <tr class="table-heading">
            <th v-for="column in tableColumns"
                :key="column.id">{{column.label}}
            </th>
          </tr>
          </thead>
          <tbody class="table-body">
          <tr v-for="item in tableData"
              :key="item.period"
              :class=" !showActions ? 'no-border' : '' "
          >
            <td>{{item.period}}</td>
            <td>{{item.uptime}}</td>
            <td>{{item.downtime}}</td>
            <td>{{item.incidents}}</td>
            <td>{{item.longestIncident}}</td>
            <td>{{item.averageIncident}}</td>
          </tr>
          </tbody>
          <div class="table-date-estimator" v-if="showActions">
            <div class="row table-date-estimator-container">
              <div class="col-auto pr-0">
                <span class="table-date-estimator-heading d-block">FROM</span>
                <base-date-picker placeholder="Date From"
                                  :config="datePickerFromConfig"
                                  v-model="window.starts_at"
                                  :value="window.starts_at"
                                  class="table-datepicker" />
              </div>
              <div class="col-auto">
                <span class="table-date-estimator-heading d-block">TO</span>
                <base-date-picker placeholder="Date To"
                                  :config="datePickerToConfig"
                                  v-model="window.ends_at"
                                  :value="window.ends_at"
                                  class="table-datepicker" />
              </div>
              <div class="col-auto ">
                <base-button
                  type="button"
                  class="btn table-btn"
                  @click="getCalculations">Calculate</base-button>
              </div>
            </div>
          </div>
          <tfoot v-if="dates" class="table-footer">
          <tr>
            <td>{{dates}}</td>
            <td>{{calculatedStatistics.uptime}}</td>
            <td>{{calculatedStatistics.downtime}}</td>
            <td>{{calculatedStatistics.incidents}}</td>
            <td>{{calculatedStatistics.longestIncident}}</td>
            <td>{{calculatedStatistics.averageIncident}}</td>
          </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import monitorApi from '@/api/monitorsApi.js'

import BaseDatePicker from '@/components/Base/BaseDatePicker.vue'
import BaseButton from '@/components/Base/BaseButton.vue'

import moment from 'moment'

export default {
  name: 'StatisticTable',

  components: { BaseButton, BaseDatePicker },

  props: {
    monitor: {
      required: true,
      type: Object
    },
    showActions: {
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
      calculatedStatistics: {
        uptime: null,
        downtime: null,
        incidents: null,
        longestIncident: null,
        averageIncident: null
      },
      tableColumns: [
        { id: 1, label: 'Time Period' },
        { id: 2, label: 'Uptime' },
        { id: 3, label: 'Downtime' },
        { id: 4, label: 'Incidents' },
        { id: 5, label: 'Longest Incident' },
        { id: 6, label: 'Avg. Incident' }
      ],
      tableData: [],
      datePickerFromConfig: {
        enableTime: true,
        altInput: true,
        altFormat: 'd/m/Y',
        closeOnSelect: true,
        maxDate: new Date(),
        defaultHour: 0
      },
      datePickerToConfig: {
        enableTime: true,
        altInput: true,
        altFormat: 'd/m/Y',
        closeOnSelect: true,
        maxDate: new Date(),
        defaultHour: 23,
        defaultMinute: 59
      },
      window: {
        starts_at: '',
        ends_at: ''
      }
    }
  },

  computed: {
    dates () {
      return this.calculatedStatistics.downtime
        ? `${this.formatDate(this.window.starts_at, '/')} - ${this.formatDate(this.window.ends_at, '/')}`
        : null
    }
  },

  async mounted () {
    const response = await monitorApi.getStatistics(this.monitor.id)
    this.tableData = this.formatStatisticData(response.data)
  },

  methods: {
    calculateTime (data) {
      const duration = moment.duration(data, 'seconds')
      const days = Math.floor(duration.asDays())
      const hours = duration.hours()

      let time = ''

      if (days > 0) time += `${days} days, `
      if (hours > 0) time += `${hours} hours, `
      time += `${duration.minutes()} minutes`

      return time
    },

    calculatePercents (data) {
      return `${Math.floor(data * 10000) / 100}%`
    },

    formatPeriod (period) {
      if (period === '1day') return 'Today'
      if (period === '7days') return 'Last 7 days'
      if (period === '30days') return 'Last 30 days'
      if (period === '90days') return 'Last 90 days'

      return period
    },

    formatDate (date, format) {
      if (format === '/') return moment(date).format('DD/MM/YYYY')
      else if (format === '-') return moment(date).format('DD-MM-YYYY')
      else return ''
    },

    formatStatisticData (data) {
      const formattedData = Object.entries(data).map(([period, values]) => ({
        period: this.formatPeriod(period),
        uptime: this.calculatePercents(values.uptime),
        downtime: this.calculateTime(values.downtime),
        incidents: values.incidents,
        longestIncident: this.calculateTime(values.longestIncident),
        averageIncident: this.calculateTime(values.averageIncident)
      }))
      return formattedData
    },

    async getCalculations () {
      const dateFrom = this.window.starts_at
      const dateTo = this.window.ends_at

      if (dateFrom && dateTo) {
        const response = await monitorApi.getStatisticsByRange(this.monitor.id, {
          from: this.formatDate(dateFrom, '-'),
          to: this.formatDate(dateTo, '-')
        })

        const { uptime, downtime, incidents, longestIncident, averageIncident } = response.data

        this.calculatedStatistics = {
          uptime: this.calculatePercents(uptime),
          downtime: this.calculateTime(downtime),
          incidents,
          longestIncident: this.calculateTime(longestIncident),
          averageIncident: this.calculateTime(averageIncident)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .card {
    &-body {
      box-shadow: 0px 2px 4px rgba(25, 34, 29, 0.05);
      padding: 15px;
    }
  }

  .table {
    position: relative;
    & td {
      font-size: 15px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: -0.019em;
      color: #333333
    }
    &-heading{
      font-size: 15px;
      line-height: 24px;
      letter-spacing: -0.019em;
      color:#828282;
      & th {
        min-width: 150px;
        font-weight: 500;
      }
    }

    &-body {
      & tr > td:first-child {
        font-size: 15px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: -0.019em;
        color: #828282;
      }

      & tr:last-child > td {
        border-bottom: 1px solid map-get($colors, gray-4);
      }
      & .no-border:last-child > td {
        border-bottom: none;
      }
    }

     &-footer{
      & td {
        border-top-style: none;
      }
       & tr > td:first-child {
         color: #828282;
       }
    }

    &-btn {
      margin-top: -3px;
      padding: 13px 18px;
      font-size: 15px;
      font-weight: 500;
      box-shadow: 0px 2px 4px rgba(25, 34, 29, 0.05);
      background-color: map-get($colors, purple-1);
      color: map-get($colors, white);
      border: none;
      border-radius: 5px;
    }

    &-date-estimator {
      display: flex;
      align-items: center;
      max-width: 0;
      height: 87px;
      margin-bottom: 12px;
      padding: 22px 0 0 0;

        &-container {
          position: absolute;
          align-items: center;
          width: 100%;
          padding: 34px 0 0 0;
        }

        &-heading {
          padding-bottom: 5px;
          font-size: 10px;
          font-weight: 600;
          line-height: 12px;
          letter-spacing: 0.05em;
          color: #828282;
        }
    }

    &-datepicker {
      ::v-deep .form-control {
        padding: 21px 10px 21px 42px;
        box-shadow: 0px 2px 4px rgba(25, 34, 29, 0.05);
        border: 1px solid #e9ebec;
        border-radius: 6px;
        text-align: left;
      }

      &:before {
          content: '';
          position: absolute;
          top: 13px;
          left: 15px;
          min-width: 20px;
          height: 20px;
          padding: 0;
          background: url('../../../../assets/images/calendar.svg') no-repeat;
          pointer-events: none;
        }
        &:after {
          content: '';
          position: absolute;
          top: 20px;
          right: 10px;
          min-width: 11px;
          height: 8px;
          background: url('../../../../assets/images/chevron-down.svg') no-repeat;
          pointer-events: none;
        }
        ::placeholder {
          //padding-left: 20px;
        }
    }
  }
</style>
