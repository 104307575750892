<template>
  <div class="FiltersLocation">
    <div class="toggle"
         :class="{ open }"
         ref="link">
      <div class="location-value"></div>
      <icon-dropdown width="18" height="18" class="icon icon-dropdown"/>
    </div>

    <base-dropdown
      v-model="defaultDateFromFormatted"
      :value="defaultDateFromFormatted"
      item-label="label"
      class="period-dropdown mx-3"
      track-by="id"
      :options="periodOptions"
      :allow-empty="false"
      :searchable="false"
      @input="updatePeriod"
    />

    <div ref="dropdown" class="dropdown px-0">
      <div class="title pl-1 mt-2">By Continent</div>
        <node v-for="option in continentOptions"
              :key="option.id"
              class="location-dropdown location-dropdown-continents mb-0"
              :node="option"
              @toggle="updateContinent(option, $event)"
        />

      <div class="title pl-1 mt-2 mb-1">Location List</div>
      <node v-for="location in locationOptions"
            :key="location.id"
            class="location-dropdown"
            :node="location"
            @toggle="location.selected = $event"
      />
    </div>
  </div>
</template>

<script>
import Node from './FilterLocationNode.vue'

import tippy from 'tippy.js'
import moment from 'moment'
import { reponseTimeTableUpdate } from '@/main'

export default {
  components: {
    Node
  },

  model: {
    prop: 'nodes',
    event: 'update'
  },

  props: {
    monitor: {
      required: true,
      type: Object
    },

    locations: {
      required: true,
      type: Array
    },

    continents: {
      required: true,
      type: Array
    },

    defaultContinent: {
      required: true,
      type: String
    },

    defaultDateFrom: {
      required: true,
      type: String
    }
  },

  data () {
    return {
      tippyInstance: null,
      open: false,
      dateFrom: null,
      periodOptions: [
        { id: 1, label: 'Last 30 days', value: 'month' },
        { id: 2, label: 'Today', value: '' },
        { id: 3, label: 'Yesterday', value: 'day' },
        { id: 4, label: 'Last 7 days', value: 'week' },
        { id: 5, label: 'This Week', value: 'thisWeek' },
        { id: 6, label: 'Last Week', value: 'lastWeek' },
        { id: 7, label: 'This Month', value: 'thisMonth' },
        { id: 8, label: 'Last Month', value: 'lastMonth' },
        { id: 9, label: 'All Time', value: 'year' }
      ],
      continentOptions: [],
      locationOptions: [],
      defaultDateFromFormatted: null
    }
  },

  mounted () {
    this.setTippy()

    this.transformLocations(this.locations, 'locationOptions')
    this.transformLocations(this.continents, 'continentOptions')
    this.defaultDateFromFormatted = this.periodOptions.find(item => item.value === this.defaultDateFrom)
    this.updatePeriod(this.defaultDateFromFormatted)
  },

  watch: {
    locations: {
      handler: function () {
        this.transformLocations(this.locations, 'locationOptions')
      },
      deep: true
    },

    locationOptions: {
      handler: function (newValue) {
        const selectedLocations = newValue.filter(location => location.selected === true).map(location => location.title)

        this.$emit('updateLocation', selectedLocations)
      },
      deep: true
    }
  },

  methods: {
    transformLocations (locations, area) {
      this[area] = locations.map(location => ({
        id: crypto.randomUUID(),
        title: location,
        selected: area === 'locationOptions' ? true : location === this.defaultContinent,
        location: location.toLowerCase().split(' ').join('-')
      }))
    },

    firstDayInPreviousMonth () {
      const now = new Date()
      const dateObj = new Date(now.getFullYear(), now.getUTCMonth() - 1, 1)
      return new Date(dateObj.getTime() - dateObj.getTimezoneOffset() * 60000).toISOString().split('T')[0]
    },

    firstDayInTheCurrentWeek () {
      const d = new Date()
      const day = d.getDay()
      const diff = d.getDate() - day + (day === 0 ? -6 : 1) // adjust when day is sunday
      const dateObj = new Date(d.setDate(diff))
      return new Date(dateObj.getTime() - dateObj.getTimezoneOffset() * 60000).toISOString().split('T')[0]
    },

    firstDayOfTheLastWeek () {
      const d = new Date()
      const day = d.getDay()
      const diff = d.getDate() - day + (day === 0 ? -6 : 1) // adjust when day is sunday
      const dateObj = new Date(d.setDate(diff))
      return new Date(dateObj.getFullYear(), dateObj.getMonth(), dateObj.getDate() - 6).toISOString().split('T')[0]
    },

    firstDayInTheCurrentMonth () {
      const now = new Date()
      const dateObj = new Date(now.getFullYear(), now.getUTCMonth(), 1)
      return new Date(dateObj.getTime() - dateObj.getTimezoneOffset() * 60000).toISOString().split('T')[0]
    },

    updatePeriod ({ value }) {
      let period = ''
      switch (value) {
        case 'lastMonth':
          period = this.firstDayInPreviousMonth()
          break
        case 'thisMonth':
          period = this.firstDayInTheCurrentMonth()
          break
        case 'lastWeek':
          period = this.firstDayOfTheLastWeek()
          break
        case 'thisWeek':
          period = this.firstDayInTheCurrentWeek()
          break
        default:
          period = this.optionsFormatter(value)
      }

      this.$emit('updatePeriod', period)
      this.$emit('updateDefaultDateFrom', value)
      reponseTimeTableUpdate.$emit('tableUpdated', { 'period': period, 'value': value })
    },

    optionsFormatter (period) {
      return !period
        ? moment().format('YYYY-MM-DD')
        : moment().subtract(1, period).format('YYYY-MM-DD')
    },

    updateContinent (option, selected) {
      this.continentOptions = this.continentOptions.map((item) => {
        return {
          ...item,
          selected: item.id === option.id ? selected : false
        }
      })

      this.$emit('updateContinent', option.title)
    },

    setTippy () {
      this.tippyInstance = tippy(this.$refs.link, {
        content: this.$refs.dropdown,
        interactive: true,
        theme: 'light-border',
        animation: 'shift-away-subtle',
        placement: 'bottom-end',
        arrow: false,
        inertia: true,
        trigger: 'click',
        maxWidth: 'none',

        onShow: () => {
          this.open = true
        },

        onHide: () => {
          this.open = false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
    .location-dropdown{
      padding: 9px 10px;

      &-continents {
        ::v-deep {
          .flag {
            display: none;
          }

          .title {
            //margin-left: 6px;
            &:before {
              content: '';
              position: relative;
              top: 2px;
              left: 4px;
              min-width: 20px;
              height: 20px;
              background: url('../../../../assets/images/planet.svg') no-repeat;
            }
          }
        }
      }

      ::v-deep {
        .ip {
          display: none;
        }

        .base-checkbox {
          width: 100%;

          .checkmark {
            border: none;
            background-color: transparent;
          }
        }

        .checkmark {
          &:after {
            padding: 2px 3px;
            color: #6551E0;
          }
        }
      }

      &:hover {
        width: 100%;
        background-color: #6551E0;
        color: white;
        border-radius: 5px;

        ::v-deep {
          .checkmark {
            &:after {
              padding: 2px 3px;
              color: white;
            }
          }
        }
      }
    }

    .period-dropdown {
      margin-top: 19px;

      ::v-deep {
        .icon-toggle.is-open {
          transform: none !important;
        }

        .multiselect {
          width: 140px;

          &:hover {
            cursor: pointer;
          }

          .multiselect__tags {
            padding: 11px 30px 0 16px;
          }

          .multiselect__option {
            background-color: white;
            color: #333;

            &:hover {
              background-color: #6551E0;
              color: white;
            }
          }

          .multiselect__option--selected {
            background-color: white;
            color: #333;

            &:hover {
              background-color: #6551E0;
              color: white;
            }
          }

          &__single {
            display: flex;
            align-items: center;
            font-size: 14px;
            font-weight: 500;
            line-height: 17px;
            letter-spacing: 0.005em;
            color: #333333;
          }

          &__content-wrapper {
            margin-top: 11px;
            padding: 9px;
            border: 1px solid #e8e8e8;
            border-radius: 5px;
          }

          &__option {
            padding-left: 28px;

            &--highlight {
              border-radius: 4px;
            }

            &--selected {
              color: #333;

              &:before {
                content: '';
                position: absolute;
                top: 16px;
                left: 10px;
                padding: 5px;
                background: url('../../../../assets/images/badge-check.svg') no-repeat;
              }

              &:hover {
                &:before {
                  // content: '';
                  // position: absolute;
                  // top: 16px;
                  // left: 5px;
                  // padding: 5px;
                  background: url('../../../../assets/images/badge-check-white.svg') no-repeat;
                }
              }
            }
          }
        }
      }
    }

    .FiltersLocation {
      display: flex;
      align-items: center;

      ::v-deep {
        .tippy-box {
          max-height: 350px;
          overflow-y: scroll;
        }
        .location-dropdown{
          margin-bottom: 0;
        }
      }

      .icon {
        height: 16px;
      }

      .toggle {
        display: flex;
        align-items: center;
        // margin-right: 15px;
        height: 44px;
        padding: 6px;
        transition: 0.2s all ease;
        border: 1px solid #E9EBEC;
        box-shadow: 0px 2px 4px rgba(25, 34, 29, 0.05);
        border-radius: 6px;
        cursor: pointer;

        &.open {
          border: 1px solid map-get($colors, purple-1)
        }
      }

      .label {
        font-size: 11px;
        font-weight: 600;
        line-height: 1;
        text-transform: uppercase;
        letter-spacing: .05em;
      }

      .location-value {
        padding: 0 5px 0 11px;

        &:before {
          // content: '';
          // position: absolute;
          // top: 41px;
          // right: 225px;
          // min-width: 15px;
          // height: 15px;

          content: "";
          display: inline-block;
          min-width: 15px;
          height: 15px;
          background: url('../../../../assets/images/planet.svg') no-repeat;
          position: relative;
          top: 1px;
        }
      }

      .icon-dropdown {
        margin-left: 4px;
      }

      .dropdown {
        width: 270px;

        .title {
          font-size: 11px;
          font-weight: 700;
          margin-bottom: 8px;
          color: #BDBDBD;
          text-transform: uppercase;
        }
      }
    }
</style>
