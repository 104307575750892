<template>
  <div class="MonitorOverviewResponseTime">
    <div class="card" :class="classes">
      <div class="card-body">
        <div class="heading">Response Time</div>
        <div v-if="monitor.response_time"
             class="d-flex align-items-center">
          <div class="card-time" ref="average">{{ monitor.response_time | responseTime }}</div>
          <div class="pl-2">
            <span class="card-time-unit">Seconds</span>
            <br />
            <span class="card-time-unit-status">{{ monitor.checked_at | shortDate }}</span>
          </div>
        </div>
        <div class="no-data" v-else>Not enough data</div>
      </div>
    </div>
  </div>
</template>

<script>
import tippy from 'tippy.js'

export default {
  props: {
    monitor: {
      required: true,
      type: Object
    }
  },

  mounted () {
    tippy(this.$refs.average, {
      content: 'Average response time calculated by all nodes'
    })

    if (this.monitor.nodes.length > 1) {
      tippy(this.$refs.max, {
        content: `The ${this.slowestNode.title} response time, which is the slowest`
      })

      tippy(this.$refs.min, {
        content: `The ${this.fastestNode.title} response time, which is the fastest`
      })
    }
  },

  computed: {
    classes () {
      if (!this.monitor.response_time) {
        return ['warning']
      }

      return [
        { success: this.monitor.response_time < this.warningResponseTimeThreshold },
        {
          warning: this.monitor.response_time < this.dangerResponseTimeThreshold &&
                   this.monitor.response_time >= this.warningResponseTimeThreshold
        },
        { error: !this.monitor.response_time || this.monitor.response_time >= this.dangerResponseTimeThreshold }
      ]
    },

    nodesWithAverageResponseTime () {
      return this.monitor.nodes.filter(node => node.average_response_time)
    },

    slowestNode () {
      return this.nodesWithAverageResponseTime.reduce((prev, current) => {
        return (prev.average_response_time > current.average_response_time) ? prev : current
      })
    },

    fastestNode () {
      return this.nodesWithAverageResponseTime.reduce((prev, current) => {
        return (prev.average_response_time < current.average_response_time) ? prev : current
      })
    },

    ...mapState('system', [
      'warningResponseTimeThreshold',
      'dangerResponseTimeThreshold'
    ])
  }
}
</script>

<style lang="scss" scoped>
  .MonitorOverviewResponseTime {
    @include monitor-overview-info-card;

    .index {
      margin: 0 6px;
      border-bottom: 1px dotted #777;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
</style>
