<template>
  <div class="Overview" v-if="monitor">
    <monitor-overview :monitor="monitor" />
  </div>
</template>

<script>
import MonitorOverview from '@/components/Monitor/Overview/Overview.vue'
import monitorsApi from '@/api/monitorsApi.js'

export default {
  components: {
    MonitorOverview
  },

  metaInfo: {
    title: 'Monitor Overview'
  },

  props: {
    monitorId: {
      required: true
    }
  },

  data () {
    return {
      monitor: null
    }
  },

  async created () {
    this.monitor = await monitorsApi.find(this.monitorId)
  }
}
</script>

<style lang="scss" scoped>
  .Overview {
  }
</style>
