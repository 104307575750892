<template>
  <div class="MonitorOverviewUptime">
    <div class="card" :class="classes">
      <div class="card-body">
        <div class="heading">
          <span><a href="#"
             class="title title-toggle mr-2"
             :class="{ active: uptime === 'uptime' }"
             @click.prevent="uptime = 'uptime'">Uptime</a></span>
          <span v-if="showActions">/<a href="#"
             class="title title-toggle ml-2"
             :class="{ active: uptime === 'downtime' }"
             @click.prevent="uptime = 'downtime'">Downtime</a></span>
        </div>
        <div v-if="average_uptime_by_range"
             class="d-flex align-items-center">
          <div class="card-time"
               ref="downtime">
               {{ uptimeType | uptime }}
          </div>
        </div>
        <div class="no-data" v-else>Not enough data</div>
      </div>
    </div>
  </div>
</template>

<script>
import tippy from 'tippy.js'
import { reponseTimeTableUpdate } from '@/main'
import monitorsApi from '@/api/monitorsApi'

export default {
  props: {
    monitor: {
      required: true,
      type: Object
    },
    showActions: {
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
      uptime: 'uptime',
      average_uptime_by_range: null
    }
  },

  created () {
    reponseTimeTableUpdate.$on('tableUpdated', async (data) => {
      const response = await monitorsApi.getMonitorAverageUptimeByRange(this.monitor.id, { since: data['period'], value: data['value'] })
      if (response !== null) {
        this.average_uptime_by_range = Math.floor(response * 10000) / 10000
      } else {
        this.average_uptime_by_range = null
      }
    })
  },

  mounted () {
    tippy(this.$refs.downtime, {
      content: `Downtime. The most important index`
    })
  },

  computed: {
    uptimeType () {
      return this.uptime === 'uptime' ? this.average_uptime_by_range : 1 - this.average_uptime_by_range
    },

    classes () {
      if (!this.monitor.uptime) {
        return ['warning']
      }

      return [
        { success: this.monitor.uptime > this.warningUptimeThreshold },
        {
          warning: this.monitor.uptime <= this.warningUptimeThreshold &&
                   this.monitor.uptime > this.dangerUptimeThreshold
        },
        { error: this.monitor.uptime <= this.dangerUptimeThreshold }
      ]
    },

    ...mapState('system', [
      'warningUptimeThreshold',
      'dangerUptimeThreshold'
    ])
  }
}
</script>

<style lang="scss" scoped>
  .MonitorOverviewUptime {
    @include monitor-overview-info-card;

    .downtime {
      border-bottom: 1px dotted #777;
    }

    .heading {
        text-decoration: none;
      }
    .title {
      font-size: 15px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: -0.019em;
      color: #828282;

      &.title-toggle {
        color: map-get($colors, purple-1);

        &.active {
          color: #828282;
        }
      }
    }
    .heading .active {
        font-weight: 500;
        color: map-get($colors, purple-1);
        text-decoration: none;
        pointer-events: none;
        cursor: default;
    }

    .heading a {
        text-decoration: none;
        text-decoration: transparent;
    }
  }
</style>
