<template>
  <div class="MonitorOverviewCertificate">
    <div class="card">
      <div class="card-body">
      <div class="heading">SSL Certificate</div>
      <div v-if="monitor.ssl_certificate"
            class="d-flex align-items-center">
        <div class="card-time">{{ certificateMonthsLeft }}</div>
        <div class="pl-2">
          <span class="card-time-unit d-block" v-if="certificateMonthsLeft === 1">Month left</span>
          <span class="card-time-unit d-block" v-else>Months left</span>
          <span class="card-time-unit-status">Exp. {{ monitor.ssl_certificate.expires_at | day }}</span>
        </div>
      </div>
      <div class="no-data" v-else>Not enough data</div>
    </div>
  </div>
  </div>
</template>

<script>
import tippy from 'tippy.js'
import moment from 'moment'

export default {

  props: {
    monitor: {
      required: true,
      type: Object
    }
  },

  mounted () {
    let state = this.monitor.ssl_certificate_state
    if (state === 'valid' || state === 'invalid') {
      // this.setTippy()
    }
  },

  methods: {
    setTippy () {
      this.tippy = tippy(this.$refs.link, {
        content: this.$refs.dropdown.$el,
        interactive: true,
        theme: 'light-border',
        animation: 'shift-away-subtle',
        placement: 'bottom',
        arrow: true,
        inertia: true,
        trigger: 'click',
        maxWidth: 'none'
      })
    }
  },

  computed: {
    certificateMonthsLeft () {
      let months = moment.duration(this.monitor.ssl_certificate.expires_in, 'days').asMonths()
      if (months > 10) {
        months = Math.round(months)
      }
      return months.toFixed(1)
    },

    classes () {
      const state = this.monitor.ssl_certificate_state

      return [
        { success: state === 'valid' },
        { warning: state === null },
        { error: state === 'invalid' || state === 'not_found' }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
  .MonitorOverviewCertificate {
    @include monitor-overview-info-card;

    .certificate-link {
      border-bottom: 1px dotted #777;
    }
  }
</style>
