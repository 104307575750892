<template>
  <div class="MonitorOverviewAverageResponseTime">
    <div class="card">
      <div class="card-body">
        <div class="heading">Average Response Time</div>
          <div v-if="average_response_time_by_range"
               class="d-flex align-items-center">
            <div class="card-time">{{ average_response_time_by_range }}</div>
            <div class="pl-2">
              <span class="card-time-unit d-block">Seconds</span>
              <span class="card-time-unit-status">{{ monitor.status }}</span>
            </div>
          </div>
          <div v-else>
            <span class="no-data">Not enough data</span>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import { reponseTimeTableUpdate } from '@/main'
import monitorsApi from '@/api/monitorsApi'

export default {
  props: {
    monitor: {
      required: true,
      type: Object
    }
  },
  data () {
    return {
      average_response_time_by_range: null
    }
  },

  created () {
    reponseTimeTableUpdate.$on('tableUpdated', async (data) => {
      const response = await monitorsApi.getMonitorAverageResponseTimeByRange(this.monitor.id, { since: data['period'], value: data['value'] })
      if (response !== null) {
        this.average_response_time_by_range = Math.floor(response * 100) / 100
      } else {
        this.average_response_time_by_range = null
      }
    })
  }
}
</script>

<style lang="scss" scoped>
  .MonitorOverviewAverageResponseTime {
    @include monitor-overview-info-card;
  }
</style>
